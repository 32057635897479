import '../../App.css'

function CasinoBetPlace(props) {
    return (
        <div>
            <div
                className={`align-middle text-center text-[.75rem] px-[0] py-[3px] font-bold border-t-[none]  ${props.type == "back"
                    ? "bg-[linear-gradient(180deg,_#BEDDF4_0%,_#D4E8F8_100%)] border-b-[1px_solid_#7e97a7]"
                    : "bg-[linear-gradient(180deg,_#F3DCE2_0%,_#FAEFF2_100%)]"
                    }`}
            >
                <div className="text-center text-[12px]">
                    <div className="overflow-hidden text-center text-[.75rem] font-bold">
                        <div
                            className={`${props.type == "back"
                                ? "bg-[#beddf4]"
                                : "bg-[#f3dce2]"
                                }  text-center text-[.75rem] font-bold`}
                        >
                            <div className="w-full pr-[calc(var(--bs-gutter-x)* .5)] pl-[calc(var(--bs-gutter-x)* .5)] mr-auto ml-auto text-center text-[.75rem] font-bold">
                                <div className="flex relative !pl-[.5rem] !pb-0 !pr-[.5rem] text-center text-[.75rem] font-bold">
                                    <div className="!p-[.25rem] flex-[1_0_0%] w-full max-w-full mt-0">
                                        <button
                                            className='bg-[linear-gradient(-180deg,#fff_0,#eee_89%)] h-[40px] w-[40px] leading-[28px] float-left text-[1rem] text-[#1f72ac] p-[10px] rounded-bl-[7px] rounded-tl-[7px] border-[1px]  border-[#aaaaaa] content-[""] bg-no-repeat bg-contain flex absolute items-center justify-center rounded-none'
                                        // onClick={() => handlePriceValue(BetPlaceData.price > 0 ? parseFloat(BetPlaceData.price) - 0.01 : console.log("negative"))}
                                        >
                                            <span className='content-[""] bg-no-repeat bg-contain flex w-[20px] absolute h-[15px] text-[1.313rem] items-center justify-center leading-[28px] text-[#1f72ac]'>
                                                <svg
                                                    className="w-[19px] h-[19px]"
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    width="19px"
                                                    height="19px"
                                                    viewBox="0 0 19 19"
                                                    enableBackground="new 0 0 19 19"
                                                    xmlSpace="preserve"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M8.056,10.838H0.409V8.091h7.647 M2,8.091h16.591v2.747H8"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </button>
                                        <input
                                            className="bg-[#ffffff] text-center border-t-[1px] border-t-[#aaaaaa] border-b-[1px] border-b-[#aaaaaa] border-l-[0px] border-r-[0px] w-[59%] !h-[40px] !text-[#1e1e1e] !font-bold !text-[0.875rem] rounded-none outline-none"
                                            readOnly
                                            type="text"
                                            value={props.BetPlaceData.price}
                                            placeholder="0"
                                        />
                                        <button
                                            className="bg-[linear-gradient(-180deg,#fff_0,#eee_89%)] h-[40px] w-[40px] leading-[28px] text-[1rem] text-[#1f72ac] p-[10px] bg-no-repeat bg-contain inline-flex border-[1px]  border-[#aaaaaa] absolute !rounded-tl-[0] !rounded-br-[7px] !rounded-tr-[7px] !rounded-bl-[0] !float-right"
                                        // onClick={() => handlePriceValue(parseFloat(BetPlaceData.price) + 0.01)}
                                        >
                                            <span className="bg-no-repeat bg-contain inline-flex w-[20px] absolute h-[19px] text-[1.188rem] leading-[28px] text-[#1f72ac]">
                                                <svg
                                                    className="w-[19px] h-[19px]"
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    width="19px"
                                                    height="19px"
                                                    viewBox="0 0 19 19"
                                                    enableBackground="new 0 0 19 19"
                                                    xmlSpace="preserve"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M10.946,0.127v7.964h7.646v2.747h-7.646v8.035h-2.89v-8.035H0.409V8.091h7.647V0.127H10.946z"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>

                                    <div className="!p-[.25rem] flex-[1_0_0%] w-full max-w-full mt-0">
                                        <button
                                            className='bg-[linear-gradient(-180deg,#fff_0,#eee_89%)] h-[40px] w-[40px] leading-[28px] float-left text-[1rem] text-[#1f72ac] p-[10px] rounded-bl-[7px] rounded-tl-[7px] border-[1px]  border-[#aaaaaa] content-[""] bg-no-repeat bg-contain flex absolute items-center justify-center rounded-none'
                                            onClick={() =>
                                                props.placeStakeValue(
                                                    props.StakeValue > 0
                                                        ?  props.StakeValue - 100
                                                        : console.log("negative")
                                                )
                                            }
                                        >
                                            <span className='content-[""] bg-no-repeat bg-contain flex w-[20px] absolute h-[15px] text-[1.313rem] items-center justify-center leading-[28px] text-[#1f72ac]'>
                                                <svg
                                                    className="w-[19px] h-[19px]"
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    width="19px"
                                                    height="19px"
                                                    viewBox="0 0 19 19"
                                                    enableBackground="new 0 0 19 19"
                                                    xmlSpace="preserve"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M8.056,10.838H0.409V8.091h7.647 M2,8.091h16.591v2.747H8"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </button>
                                        <input
                                            className="bg-[#ffffff] text-center border-t-[1px] border-t-[#aaaaaa] border-b-[1px] border-b-[#aaaaaa] border-l-[0px] border-r-[0px] w-[59%] !h-[40px] !text-[#1e1e1e] !font-bold !text-[0.875rem] rounded-none outline-none"
                                            value={
                                                props.StakeValue ?  props.StakeValue : ""
                                            }
                                            onChange={(e) =>
                                                props.placeStakeValue(e.target.value)
                                            }
                                            type="text"
                                            placeholder="0"
                                        ></input>
                                        <button
                                            className="bg-[linear-gradient(-180deg,#fff_0,#eee_89%)] h-[40px] w-[40px] leading-[28px] text-[1rem] text-[#1f72ac] p-[10px] bg-no-repeat bg-contain inline-flex border-[1px]  border-[#aaaaaa] absolute !rounded-tl-[0] !rounded-br-[7px] !rounded-tr-[7px] !rounded-bl-[0] !float-right"
                                            onClick={() =>
                                                props.placeStakeValue(props.StakeValue + 100)
                                            }
                                        >
                                            <span className="bg-no-repeat bg-contain inline-flex w-[20px] absolute h-[19px] text-[1.188rem] leading-[28px] text-[#1f72ac]">
                                                <svg
                                                    className="w-[19px] h-[19px]"
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    width="19px"
                                                    height="19px"
                                                    viewBox="0 0 19 19"
                                                    enableBackground="new 0 0 19 19"
                                                    xmlSpace="preserve"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M10.946,0.127v7.964h7.646v2.747h-7.646v8.035h-2.89v-8.035H0.409V8.091h7.647V0.127H10.946z"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>

                                <div
                                    className={`grid grid-cols-12 border-t-[1px] ${props.type == "back"
                                        ? "border-t-[#7dbbe9]"
                                        : "border-t-[#dfa3b3]"
                                        }    relative !pb-0 !pt-0 !p-[.5rem] flex flex-wrap`}
                                >
                                    {props.chips?.map((chip) => {
                                        return (
                                            <div className="col-span-3 !p-[.25rem] flex-[1_0_0%] w-full max-w-full">
                                                <button
                                                    className="bg-[#f9f9f9] border-[1px] border-[#333] rounded-[4px] !text-[#000000] !font-normal !leading-[18px] !text-[0.813rem] !h-[31px] !ml-[0] !mr-[5px] !my-[0] text-center w-full"
                                                    onClick={() =>
                                                        props.placeStakeValue(
                                                            chip?.chip_value
                                                        )
                                                    }
                                                >
                                                    {chip?.chip_value}
                                                </button>
                                            </div>
                                        );
                                    })}
                                    <div className="col-span-6 !pb-0 !p-[.25rem] flex-[1_0_0%] w-full mt-0">
                                        <button
                                            className="leading-[36px] pt-0 pb-0 w-full bg-[#f9f9f9] border-[1px] border-[solid] border-[#333] rounded-[4px] !text-[#000000] !font-bold !text-[0.813rem] !h-[38px] !ml-[0] !mr-[5px] !my-[0] text-center"
                                            onClick={() => {
                                                props.cancelBetSlip();
                                            }}
                                        >
                                            {" "}
                                            Cancel{" "}
                                        </button>
                                    </div>
                                    <div className="col-span-6 !pb-0 !p-[.25rem] flex-[1_0_0%] w-full max-w-full mt-0 text-center text-[.75rem] font-bold">
                                        <button
                                            className={`!bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] text-[1rem]  px-[0.75rem] py-[0.375rem] text-[#ffffff] border-[#000000]  w-full border-[1px] border-[#000000] rounded-[5px] font-bold ${ props.StakeValue > 0
                                                ? ""
                                                : "cursor-not-allowed pointer-events-none opacity-[.65]"
                                                }`}
                                            onClick={() =>
                                                props.placing
                                                    ? console.log("Placing....")
                                                    : props.betPlace()
                                            }
                                        // onClick={() => betPlace()}
                                        >
                                            {props.placing
                                                ? "Placing...."
                                                : "Place Bet"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CasinoBetPlace;

