import '../../App.css'
import { useEffect, useRef, useState } from 'react';
import TableRow from '../../components/TableRow';
import MobileTableRow from '../../components/mobileTableRow';
import SkyLoader from '../../components/sky-loader'
import { useSelector } from 'react-redux';
import Appconfig from '../../config/config'

function Inplay() {
    const userInfos = useSelector((state) => state.events); // Use selector to access state

    const [activeCasino, setActiveCasino] = useState('popular')
    const [selectedTime, setSelectedTime] = useState('inplay')
    const [allEvents, setAllEvents] = useState([]);
    const [cricketEvents, setCricketEvents] = useState([])
    const [tennisEvents, setTennisEvents] = useState([])
    const [soccerEvents, setSoccerEvents] = useState([])


    useEffect(() => {
        if (userInfos.events.length) {
            setAllEvents(userInfos.events[0]?.competitions[0].events)
        }
    }, [userInfos.events.length])


    const casinoNav = [
        {
            'title': 'Popular',
            'category': 'popular'
        },
        {
            'title': 'Teen Patti',
            'category': 'teen-patti'
        },
        {
            'title': 'Lucky 7',
            'category': 'lucky-7'
        },
        {
            'title': 'Dragon Tiger',
            'category': 'dragon-tiger'
        },
        {
            'title': 'Baccarat',
            'category': 'baccarat'
        },
        {
            'title': 'Andar Bahar',
            'category': 'andar-bahar'
        },
        {
            'title': 'Poker',
            'category': 'poker'
        },
        {
            'title': 'Bollywood',
            'category': 'bollywood'
        },
        {
            'title': 'Region',
            'category': 'region'
        },
        {
            'title': 'Other',
            'category': 'other'
        },
    ]
    // const allCasino = [
    //     {
    //         'title': '20-20 TEENPATTI',
    //         'path': 'https://aura444.com/api/users/images/1%20Day%20Teen%20Patti-01%204.svg',
    //         'category': 'teen-patti'

    //     },
    //     {
    //         'title': 'LUCKY 7 - A',
    //         'path': '/other/LUCKY7-A.webp',
    //         'category': 'lucky-7'
    //     },
    //     {
    //         'title': '20-20 DRAGON TIGER',
    //         'path': '/other/DragonTiger.webp',
    //         'category': 'dragon-tiger'

    //     },
    //     {
    //         'title': 'BACCARAT',
    //         'path': '/other/Baccarat-min.webp',
    //         'category': 'baccarat'

    //     },
    //     {
    //         'title': 'ANDAR BAHAR - A',
    //         'path': '/other/AndarBahar2-min-min.webp',
    //         'category': 'andar-bahar'

    //     },
    //     {
    //         'title': '32 CARDS - A',
    //         'path': '/other/32Cards .webp',
    //         'category': 'other'

    //     },
    //     {
    //         'title': '20-20 POKER -  A',
    //         'path': '/other/poker-min (1).webp',
    //         'category': 'poker'

    //     },
    //     {
    //         'title': '1DAY TEEN PATTI',
    //         'path': '/other/I Day TeenPatti-min.webp',
    //         'category': 'teen-patti'
    //     },
    //     {
    //         'title': 'DRAGON TIGER',
    //         'path': '/other/Dragon-Tiger-min.webp',
    //         'category': 'dragon-tiger'

    //     },
    //     {
    //         'title': 'FAST LUCKY - 7',
    //         'path': '/other/Fast lucky7-min.webp',
    //         'category': 'lucky-7'

    //     },
    //     {
    //         'title': '1 DAY DRAGON TIGER',
    //         'path': '/other/1 Day Dragon-Tiger-min.webp',
    //         'category': 'dragon-tiger'

    //     },
    //     {
    //         'title': '29 CARD BACCARAT',
    //         'path': '/other/29 Baccarat-min.webp',
    //         'category': 'baccarat'

    //     },
    //     {
    //         'title': 'MUFLIS TEEN PATTI',
    //         'path': '/other/MUFLISTEENPATTI.webp',
    //         'category': 'teen-patti'
    //     },
    //     {
    //         'title': 'DTL - A',
    //         'path': '/other/DTL-01 3-min.webp',
    //         'category': 'dragon-tiger'

    //     },
    //     {
    //         'title': 'FAST DRAGON TIGER',
    //         'path': '/other/FAST DRAGON TIGER.webp',
    //         'category': 'dragon-tiger'
    //     },
    //     {
    //         'title': '3 CARD JUDGEMENT',
    //         'path': '/other/3 CARD JUDGEMENT - A.webp',
    //         'category': 'other'

    //     },
    //     {
    //         'title': 'AMAR AKBAR',
    //         'path': '/other/AMAR AKBAR ANTHONY.webp',
    //         'category': 'bollywood'

    //     },
    //     {
    //         'title': 'DTL TEENPATTI',
    //         'path': '/other/DTL TEENPATTI.webp',
    //         'category': 'teen-patti'
    //     },
    //     {
    //         'title': 'INA MINA DIKA',
    //         'path': '/other/INA MINA DIKA.webp',
    //         'category': 'bollywood'

    //     },
    //     {
    //         'title': 'INSTANT WORLI',
    //         'path': '/other/INSTANT WORLI.webp',
    //         'category': 'other'

    //     },
    //     {
    //         'title': 'BOLLYWOOD CASINO',
    //         'path': '/other/BOLLYWOOD CASINO.webp',
    //         'category': 'bollywood'

    //     },
    //     {
    //         'title': 'ANDAR BAHAR - C',
    //         'path': '/other/ANDAR BAHAR - C.webp',
    //         'category': 'andar-bahar'

    //     },
    //     {
    //         'title': 'EZUGI',
    //         'path': '/other/EZUGI.webp',
    //         'category': 'poker'

    //     },
    //     {
    //         'title': 'BETGAMES CASINO',
    //         'path': '/other/BETGAMES CASINO.webp',
    //         'category': 'poker'

    //     },
    //     {
    //         'title': 'TVBET',
    //         'path': '/other/TVBET.webp',
    //         'category': 'poker'

    //     },
    //     {
    //         'title': 'EVOLUTION',
    //         'path': '/other/EVOLUTION.webp',
    //         'category': 'poker'

    //     },
    //     {
    //         'title': 'LUCKY 7 - B',
    //         'path': '/other/LUCKY 7 - B.webp',
    //         'category': 'lucky-7'

    //     },
    //     {
    //         'title': '20-20 CARD RACE',
    //         'path': '/other/20-20 CARD RACE.webp',
    //         'category': 'other'

    //     },
    //     {
    //         'title': 'BACCARAT - C',
    //         'path': '/other/BACCARAT - C.webp',
    //         'category': 'baccarat'

    //     },
    //     {
    //         'title': '1 CARD METER',
    //         'path': '/other/1 CARD METER.webp',
    //         'category': 'other'

    //     },
    //     {
    //         'title': 'TRIO',
    //         'path': '/other/TRIO.webp',
    //         'category': 'other'
    //     },

    // ]
    const allCasino = [
        {
            'title': 'POINT TEEN PATTI',
            'path': 'https://gamaexchange.com/api/users/images/Point Teen Patti-min.png',
            'category': 'teen-patti',
            'id': Appconfig.casino_type_id['t20']
        },
        {
            'title': ' JOKER TEEN PATTI ',
            'path': 'https://gamaexchange.com/api/users/images/Joker Teen Patti-min.png',
            'category': 'teen-patti',
            'id': Appconfig.casino_type_id['t20']
        },
        {
            'title': 'MUFLIS TEEN PATTI',
            'path': '/other/MUFLISTEENPATTI.webp',
            'category': 'teen-patti',
            'id': Appconfig.casino_type_id['mulfisteenpatti']
        },
        {
            'title': '1DAY TEEN PATTI',
            'path': '/other/I Day TeenPatti-min.webp',
            'category': 'teen-patti',
            'id': Appconfig.casino_type_id['1daytp']
        },
        {
            'title': 'DTL TEENPATTI',
            'path': '/other/DTL TEENPATTI.webp',
            'category': 'teen-patti',
            'id': Appconfig.casino_type_id['dtlteenpatti']
        },
        {
            'title': '20-20 TEENPATTI',
            'path': 'https://aura444.com/api/users/images/1%20Day%20Teen%20Patti-01%204.svg',
            'category': 'teen-patti',
            'id': Appconfig.casino_type_id['t20']
        },
        {
            'title': '20-20 POKER - A',
            'path': '/other/poker-min (1).webp',
            'category': 'poker',
            'id': Appconfig.casino_type_id['poker20A']
        },
        {
            'title': '20-20 POKER - B',
            'path': 'https://gamaexchange.com/api/users/images/20-20 Poker-min.png',
            'category': 'poker',
            'id': Appconfig.casino_type_id['poker20A']
        },
        {
            'title': '29 CARD BACCARAT',
            'path': '/other/29 Baccarat-min.webp',
            'category': 'baccarat',
            'id': Appconfig.casino_type_id['baccaratcard29']
        },
        {
            'title': ' BACCARAT - A ',
            'path': 'https://gamaexchange.com/api/users/images/BACCARAT-2.png',
            'category': 'baccarat',
            'id': Appconfig.casino_type_id['baccarat-A']

        },
        {
            'title': 'BACCARAT',
            'path': '/other/Baccarat-min.webp',
            'category': 'baccarat',
            'id': Appconfig.casino_type_id['baccarat']
        },
        {
            'title': 'BACCARAT - C',
            'path': '/other/BACCARAT - C.webp',
            'category': 'baccarat',
            'url': 'BACCARAT-C',
            'id': Appconfig.casino_type_id['baccarat-C']
        },
        {
            'title': 'DRAGON TIGER',
            'path': '/other/Dragon-Tiger-min.webp',
            'category': 'dragon-tiger',
            'id': Appconfig.casino_type_id['dt']
        },
        {
            'title': '20-20 DRAGON TIGER',
            'path': '/other/DragonTiger.webp',
            'category': 'dragon-tiger',
            'id': Appconfig.casino_type_id['dt20']
        },
        {
            'title': 'FAST DRAGON TIGER',
            'path': '/other/FAST DRAGON TIGER.webp',
            'category': 'dragon-tiger',
            'id': Appconfig.casino_type_id['fdt']
        },
        {
            'title': '1 DAY DRAGON TIGER',
            'path': '/other/1 Day Dragon-Tiger-min.webp',
            'category': 'dragon-tiger',
            'id': Appconfig.casino_type_id['1daydt']
        },
        {
            'title': 'DTL - A',
            'path': '/other/DTL-01 3-min.webp',
            'category': 'dragon-tiger',
            'id': Appconfig.casino_type_id['1daydt']
        },
        {
            'title': '32 CARDS - B',
            'path': 'https://gamaexchange.com/api/users/images/32 cards-min-min.png',
            'category': 'other',
            'id': Appconfig.casino_type_id['32c']
        },
        {
            'title': '32 CARDS - A',
            'path': '/other/32Cards .webp',
            'category': 'other',
            'id': Appconfig.casino_type_id['32c']
        },

        {
            'title': 'ANDAR BAHAR - A',
            'path': '/other/AndarBahar2-min-min.webp',
            'category': 'andar-bahar',
            'id': Appconfig.casino_type_id['ab']
        },
        {
            'title': 'ANDAR BAHAR - C',
            'path': 'https://gamaexchange.com/api/users/images/AndarBahar3-min.png',
            'category': 'andar-bahar',
            'id': Appconfig.casino_type_id['ab']
        },
        {
            'title': 'LUCKY 7 - A',
            'path': '/other/LUCKY7-A.webp',
            'category': 'lucky-7',
            'id': Appconfig.casino_type_id['Lucky7A']
        },
        {
            'title': 'FAST LUCKY - 7',
            'path': '/other/Fast lucky7-min.webp',
            'category': 'lucky-7',
            'id': Appconfig.casino_type_id['fl7']
        },
        {
            'title': 'LUCKY 7 - B',
            'path': '/other/LUCKY 7 - B.webp',
            'category': 'lucky-7',
            'url': 'LUCKY-7-B',
            'id': Appconfig.casino_type_id['Lucky7B']

        },
        {
            'title': '3 CARD JUDGEMENT',
            'path': '/other/3 CARD JUDGEMENT - A.webp',
            'category': 'other',
            'id': Appconfig.casino_type_id['3cardJ']

        },
        {
            'title': 'Casino War',
            'path': '/other/CasinoWar-min.png',
            'category': 'other',
            'id': Appconfig.casino_type_id['casinowar']

        },
        {
            'title': 'INSTANT WORLI',
            'path': '/other/INSTANT WORLI.webp',
            'category': 'other',
            'id': Appconfig.casino_type_id['instantworli']
        },
        {
            'title': 'BOLLYWOOD CASINO',
            'path': '/other/BOLLYWOOD CASINO.webp',
            'category': 'bollywood',
        },
        {
            'title': 'INA MINA DIKA',
            'path': '/other/INA MINA DIKA.webp',
            'category': 'bollywood',
        },

        {
            'title': 'AMAR AKBAR',
            'path': '/other/AMAR AKBAR ANTHONY.webp',
            'category': 'bollywood',
        },
        {
            'title': 'LOTTERY',
            'path': '/other/Lottery 1-min.png',
            'category': 'other',
        },
        {
            'title': '20-20 CARD RACE',
            'path': '/other/20-20 CARD RACE.webp',
            'category': 'other',
            'url': '20-20-CARD-RACE'

        },
        {
            'title': '1 CARD METER',
            'path': '/other/1 CARD METER.webp',
            'category': 'other',
            'url': '1-CARD-METER'
        },
        {
            'title': '1 CARD 20-20',
            'path': '/other/1 Card-min.png',
            'category': 'other',
            // 'url': '1-CARD-METER'
        },
        {
            'title': 'TRIO',
            'path': '/other/TRIO.webp',
            'category': 'other',
            'url': 'TRIO'
        },
        {
            'title': 'KBC',
            'path': '/other/KBC-min.png',
            'category': 'other',
            'url': 'KBC'
        },
        {
            'title': 'CASINO METER',
            'path': '/other/CasinoMeter-min.png',
            'category': 'other',
            'url': 'CASINO-METER'
        },
        {
            'title': 'EVOLUTION',
            'path': '/other/EVOLUTION.webp',
            'category': 'poker',
            'url': 'EVOLUTION'
        },
        {
            'title': 'TVBET',
            'path': '/other/TVBET.webp',
            'category': 'poker',
        },
        {
            'title': 'BETGAMES CASINO',
            'path': '/other/BETGAMES CASINO.webp',
            'category': 'poker',
        },
        {
            'title': 'EZUGI',
            'path': '/other/EZUGI.webp',
            'category': 'poker',
        },
        {
            'title': 'VIMAAN',
            'path': '/other/',
            'category': 'other',
            'url': 'VIMAAN'
        },
        // {
        //     'title': 'EVOLUTION',
        //     'path': '/other/EVOLUTION.webp',
        //     'category': 'poker',
        //     'url': 'EVOLUTION'
        // },
        // {
        //     'title': 'TVBET',
        //     'path': '/other/TVBET.webp',
        //     'category': 'poker',
        // },
        // {
        //     'title': 'BETGAMES CASINO',
        //     'path': '/other/BETGAMES CASINO.webp',
        //     'category': 'poker',
        // },
        // {
        //     'title': 'EZUGI',
        //     'path': '/other/EZUGI.webp',
        //     'category': 'poker',
        // },
        // {
        //     'title': '20-20 DRAGON TIGER',
        //     'path': '/other/DragonTiger.webp',
        //     'category': 'dragon-tiger',
        //     'id': Appconfig.casino_type_id['dt20']
        // },
        // {
        //     'title': '1 DAY DRAGON TIGER',
        //     'path': '/other/1 Day Dragon-Tiger-min.webp',
        //     'category': 'dragon-tiger',
        //     'id': Appconfig.casino_type_id['1daydt']
        // },
        // {
        //     'title': 'FAST DRAGON TIGER',
        //     'path': '/other/FAST DRAGON TIGER.webp',
        //     'category': 'dragon-tiger',
        // },
        // {
        //     'title': 'INSTANT WORLI',
        //     'path': '/other/INSTANT WORLI.webp',
        //     'category': 'other',
        // },
        // {
        //     'title': 'BOLLYWOOD CASINO',
        //     'path': '/other/BOLLYWOOD CASINO.webp',
        //     'category': 'bollywood',
        // },
        // {
        //     'title': 'ANDAR BAHAR - C',
        //     'path': '/other/ANDAR BAHAR - C.webp',
        //     'category': 'andar-bahar',
        // },
        // {
        //     'title': '20-20 CARD RACE',
        //     'path': '/other/20-20 CARD RACE.webp',
        //     'category': 'other',
        //     'url': '20-20-CARD-RACE'

        // },
        // {
        //     'title': '1 CARD METER',
        //     'path': '/other/1 CARD METER.webp',
        //     'category': 'other',
        //     'url': '1-CARD-METER'
        // },
        // {
        //     'title': 'TRIO',
        //     'path': '/other/TRIO.webp',
        //     'category': 'other',
        //     'url': 'TRIO'
        // },

    ]

    const [casino, setCasino] = useState(allCasino)
    useEffect(() => (
        setCasino(activeCasino == 'popular' ? allCasino : allCasino.filter(e => e.category == activeCasino))
    ), [activeCasino])


    const scrollableDivRef = useRef(null);
    const scrollToItem = (index) => {
        const itemElement = scrollableDivRef.current.querySelector(`#item-${index}`);
        if (itemElement) {
            itemElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
    };

    useEffect(() => {
        if (allEvents.length > 0 && selectedTime == 'inplay') {
            const tempCricketEvents = allEvents.filter(event => event.is_inplay == 'True' && event.event_type == 4)
            setCricketEvents(tempCricketEvents)
            const tempTennisEvents = allEvents.filter(event => event.is_inplay == 'True' && event.event_type == 2)
            setTennisEvents(tempTennisEvents)
            const tempSoccerEvents = allEvents.filter(event => event.is_inplay == 'True' && event.event_type == 1)
            setSoccerEvents(tempSoccerEvents)

        }
        if (allEvents.length > 0 && selectedTime == 'today') {
            const tempCricketEvents = allEvents.filter(event => new Date(event.open_date).toDateString() == new Date().toDateString() && event.event_type == 4)
            setCricketEvents(tempCricketEvents)
            const tempTennisEvents = allEvents.filter(event => new Date(event.open_date).toDateString() == new Date().toDateString() && event.event_type == 2)
            setTennisEvents(tempTennisEvents)
            const tempSoccerEvents = allEvents.filter(event => new Date(event.open_date).toDateString() == new Date().toDateString() && event.event_type == 1)
            setSoccerEvents(tempSoccerEvents)
        }
        if (allEvents.length > 0 && selectedTime == 'tomorrow') {
            const tempCricketEvents = allEvents.filter(event => new Date(event.open_date).toLocaleDateString() > new Date().toLocaleDateString() && event.event_type == 4);
            setCricketEvents(tempCricketEvents)
            const tempTennisEvents = allEvents.filter(event => new Date(event.open_date).toDateString() > new Date().toDateString() && event.event_type == 2)
            setTennisEvents(tempTennisEvents)
            const tempSoccerEvents = allEvents.filter(event => new Date(event.open_date).toDateString() > new Date().toDateString() && event.event_type == 1)
            setSoccerEvents(tempSoccerEvents)
        }
    }, [selectedTime, allEvents])


    return (
        <>
            {!allEvents.length > 0 && (
                <SkyLoader />
            )}
            {/* Desktop */}
            <div className='hidden lg:grid grid-cols-12 ' >
                <div className='col-span-12  '>
                    <div className="grid grid-cols-12 ">
                        <div className='col-span-12 mb-[1rem]'>
                            <ul className='py-[0] relative w-[49.55%] mx-[0] my-[10px] rounded-[5px] flex'>
                                <li className={`rounded-tl-[4px] rounded-br-[0] rounded-tr-[0] rounded-bl-[4px] w-[33%] block border-[1px] border-[solid] border-[#3B5160] border-[1px_0]`}>
                                    <button type='button' className={`rounded-tl-[3px] rounded-br-[0] rounded-tr-[0] rounded-bl-[3px] border-l-[1px_solid_#3B5160]  border-r-[1px_solid_#3B5160] text-[0.813rem] leading-[27px] block text-center font-bold cursor-pointer no-underline w-full ${selectedTime == 'inplay' ? `bg-[#3B5160] text-[#ffffff]` : `bg-[#ffffff] text-[#3B5160]`}`} onClick={() => setSelectedTime('inplay')}>
                                        In-Play
                                    </button>
                                </li>

                                <li className={`rounded-br-[0] rounded-tr-[0]  w-[33%] block border-y-[1px] border-[solid] border-[#3B5160] border-[1px_0]`}>
                                    <button type='button' className={`border-r-[1px_solid_#3B5160]  text-[0.813rem] leading-[27px] block text-center font-bold cursor-pointer no-underline w-full ${selectedTime == 'today' ? `bg-[#3B5160] text-[#ffffff]` : `bg-[#ffffff] text-[#3B5160]`}`} onClick={() => setSelectedTime('today')}>
                                        Today
                                    </button>
                                </li>

                                <li className={` rounded-tl-[0] rounded-br-[4px] rounded-tr-[4px] rounded-bl-[0] w-[33%] block [list-style:none] border-[1px] border-[solid] border-[#3B5160] border-[1px_0]`}>
                                    <button type='button' className={`rounded-tl-[0] rounded-br-[3px] rounded-tr-[3px] rounded-bl-[0]  border-r-[1px_solid_#3B5160]  text-[0.813rem] leading-[27px] block text-center font-bold cursor-pointer no-underline w-full ${selectedTime == 'tomorrow' ? `bg-[#3B5160] text-[#ffffff]` : `bg-[#ffffff] text-[#3B5160]`}`} onClick={() => setSelectedTime('tomorrow')}>
                                        Tomorrow
                                    </button>
                                </li>

                            </ul>
                        </div>
                        {cricketEvents.length > 0 && (
                            <div className='col-span-12  mb-[10px]'>
                                <div className='bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] '>
                                    <h3 className='bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] text-[#ffffff] text-[0.875rem] leading-[30px] font-bold pl-[10px] m-0 cursor-pointer'>Cricket </h3>
                                </div>
                                <table className="w-full">
                                    <thead className="#dddcd6 bg-[#dddcd6] ">
                                        <tr>
                                            <th scope="col" className="text-[0.688rem] "></th>
                                            <th scope="col" className="text-[0.688rem] "></th>
                                            <th scope="col" className="text-[0.688rem] ">1</th>
                                            <th scope="col" className="text-[0.688rem] ">X</th>
                                            <th scope="col" className="text-[0.688rem] ">2</th>
                                        </tr>
                                    </thead>
                                    <tbody className='bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)]'>
                                        {
                                            cricketEvents.map((event, index) => (
                                                <TableRow key={index} row={event} inplay={true} />
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {soccerEvents.length > 0 && (
                            <div className='col-span-12  mb-[10px]'>
                                <div className='bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] '>
                                    <h3 className='bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] text-[#ffffff] text-[0.875rem] leading-[30px] font-bold pl-[10px] m-0 cursor-pointer'>Soccer </h3>
                                </div>
                                <table className="w-full">
                                    <thead className="#dddcd6 bg-[#dddcd6] ">
                                        <tr>
                                            <th scope="col" className="text-[0.688rem] "></th>
                                            <th scope="col" className="text-[0.688rem] "></th>
                                            <th scope="col" className="text-[0.688rem] ">1</th>
                                            <th scope="col" className="text-[0.688rem] ">X</th>
                                            <th scope="col" className="text-[0.688rem] ">2</th>
                                        </tr>
                                    </thead>
                                    <tbody className='bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)]'>
                                        {
                                            soccerEvents.map((event, index) => (
                                                <TableRow key={index} row={event} inplay={true} />
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        )}


                        {tennisEvents.length > 0 && (
                            <div className='col-span-12 mb-[10px]'>
                                <div className='bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] '>
                                    <h3 className='bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] text-[#ffffff] text-[0.875rem] leading-[30px] font-bold pl-[10px] m-0 cursor-pointer'>Tennis  </h3>
                                </div>
                                <table className="w-full">
                                    <thead className="#dddcd6 bg-[#dddcd6] ">
                                        <tr>
                                            <th scope="col" className="text-[0.688rem] "></th>
                                            <th scope="col" className="text-[0.688rem] "></th>
                                            <th scope="col" className="text-[0.688rem] ">1</th>
                                            <th scope="col" className="text-[0.688rem] ">X</th>
                                            <th scope="col" className="text-[0.688rem] ">2</th>
                                        </tr>
                                    </thead>
                                    <tbody className='bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)]'>
                                        {tennisEvents.map((event, index) => (
                                            // <tr className="text-[0.75rem] border-[1px] border-[solid] border-[#c8ced3]">
                                            //     <td className='!pl-[10px] !pr-[10px] !py-[3px]'>
                                            //         <a href='#' className='flex flex-wrap gap-x-2 items-center'>
                                            //             <span className='flex items-center gap-x-1 text-[#2789ce] font-bold' >
                                            //                 {event.event_name}
                                            //             </span>
                                            //             <span className='!text-[12px] font-bold in-play-effect'>In-Play</span>
                                            //         </a>
                                            //     </td>
                                            //     <td className='!pl-[10px] !pr-[10px] !py-[3px] '>
                                            //         <div className='flex items-center gap-1'>
                                            //             <span className='text-white bg-[linear-gradient(-180deg,#f6a21e,#cb7e05)] pl-[2px] pr-[4px] py-[0] text-center italic font-bold rounded-[4px]'></span>
                                            //             <span className='text-white bg-[linear-gradient(180deg,_#0a92a5_15%,_#076875_100%)] px-[5px] py-[0] text-center italic font-bold rounded-[4px]'></span>
                                            //             <span className='text-white bg-[linear-gradient(180deg,_#f26d1c_15%,_#d14100_100%)] px-[5px] py-[0] text-center italic font-bold rounded-[4px]'></span>
                                            //         </div>
                                            //     </td>
                                            //     <td className="!pl-[10px] !pr-[10px] !py-[3px] w-[120px]">
                                            //         <div className='flex items-center  '>
                                            //             <span className='inline-flex text-[0.688rem] bg-[#72bbef] text-[#1e1e1e] px-[10px] py-[2px] font-bold min-w-[50px] '>
                                            //                 3.55
                                            //             </span>
                                            //             <span className='inline-flex text-[0.688rem] bg-[#faa9ba] text-[#1e1e1e] px-[10px] py-[2px] font-bold min-w-[50px]'>
                                            //                 3.6
                                            //             </span>
                                            //         </div>
                                            //     </td>
                                            //     <td className="!pl-[10px] !pr-[10px] !py-[3px] w-[120px]">
                                            //         <div className='flex items-center '>
                                            //             <span className='inline-flex text-[0.688rem] bg-[#72bbef] text-[#1e1e1e] px-[10px] py-[2px] font-bold min-w-[50px] '>
                                            //                 5.2
                                            //             </span>
                                            //             <span className='inline-flex text-[0.688rem] bg-[#faa9ba] text-[#1e1e1e] px-[10px] py-[2px] font-bold min-w-[50px]'>
                                            //                 5.3
                                            //             </span>
                                            //         </div>
                                            //     </td>
                                            //     <td className="!pl-[10px] !pr-[10px] !py-[3px] w-[120px]">
                                            //         <div className='flex items-center '>
                                            //             <span className='inline-flex text-[0.688rem] bg-[#72bbef] text-[#1e1e1e] px-[10px] py-[2px] font-bold min-w-[50px] '>
                                            //                 4.8
                                            //             </span>
                                            //             <span className='inline-flex text-[0.688rem] bg-[#faa9ba] text-[#1e1e1e] px-[10px] py-[2px] font-bold min-w-[50px]'>
                                            //                 5
                                            //             </span>
                                            //         </div>
                                            //     </td>
                                            // </tr>
                                            <TableRow key={index} row={event} inplay={true} />
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        )}
                        <div className='col-span-12 '>
                            <ul className="flex flex-nowrap overflow-x-auto overflow-y-hidden  text-sm font-medium text-center text-gray-500  [scrollbar-width:none] bg-[linear-gradient(-180deg,#f6a21e_0,_#cb7e05_100%)]">
                                {casinoNav.map((item) => (
                                    <li className="flex px-[4px] py-[2px]">
                                        <button className={`p-[7px] min-w-[60px] text-[0.75rem] font-bold whitespace-nowrap uppercase [transition:color_.15s_ease-in-out,_background-color_.15s_ease-in-out,_border-color_.15s_ease-in-out] ${activeCasino == item.category ? `text-white` : `text-black border-r-[1px] border-black rounded-[40px]`}  `} onClick={() => setActiveCasino(item.category)}>{item.title}</button>
                                    </li>
                                ))}
                            </ul>
                            <div className="grid grid-cols-12 gap-x-6 bg-[url('/public/other/casinobg.webp')] bg-contain bg-fixed p-[0.938rem] border-b-[1px] border-[#7e97a7]">
                                {
                                    casino.map((casino) => (
                                        <div className='col-span-3  rounded-[5px] mb-[15px] cursor-pointer [box-shadow:0px_2px_4px]'>
                                            <div className='relative h-[16vh] bg-[#000]  rounded-tl-[5px] rounded-tr-[5px]'>
                                                <div className='clip'></div>
                                                <div className='clip2'>
                                                    <img title="" alt="" className="w-full h-full" src={casino.path}></img>
                                                </div>
                                            </div>
                                            <div className='casinoFont w-full text-center text-[9px] rounded-bl-[5px] rounded-br-[5px] font-bold uppercase bg-[linear-gradient(-180deg,_#f6a21e_0,_#cb7e05_100%)]'>
                                                {casino.title}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >



            {/* Mobile */}
            <div className='grid lg:hidden grid-col-12 relative top-[56px]'>

                <div className='col-span-12'>
                    <ul className='!pl-[6px] !pr-[3px] !py-[0] !w-[100%] mx-[0] my-[3px] flex'>
                        <li className={`rounded-tl-[4px] rounded-br-[0] rounded-tr-[0] rounded-bl-[4px] w-[33%] block [list-style:none] border-[1px] border-[solid] border-[#3b5160] border-[1px_0]`}>
                            <button type='button' className={`rounded-tl-[3px] !leading-[8.8vw] font-bold rounded-br-[0] rounded-tr-[0] rounded-bl-[3px] border-l-[1px_#3b5160] w-full !leading-[40px] text-[0.938rem] ${selectedTime == 'inplay' ? `bg-[#3B5160] text-[#ffffff]` : `bg-[#ffffff] text-[#3B5160]`}`} onClick={() => setSelectedTime('inplay')}>
                                In-Play
                            </button>
                        </li>

                        <li className={`rounded-br-[0] rounded-tr-[0]  w-[33%] !leading-[8.8vw] block border-y-[1px] border-[solid] border-[#3B5160] border-[1px_0]`}>
                            <button type='button' className={`rounded-tl-[3px] !leading-[8.8vw] font-bold rounded-br-[0] rounded-tr-[0] rounded-bl-[3px] border-l-[1px_#3b5160] w-full !leading-[40px] text-[0.938rem] ${selectedTime == 'today' ? `bg-[#3B5160] text-[#ffffff]` : `bg-[#ffffff] text-[#3B5160]`}`} onClick={() => setSelectedTime('today')}>
                                Today
                            </button>
                        </li>

                        <li className={` rounded-tl-[0] rounded-br-[4px] !leading-[8.8vw] rounded-tr-[4px] rounded-bl-[0] w-[33%] block [list-style:none] border-[1px] border-[solid] border-[#3B5160] border-[1px_0]`}>
                            <button type='button' className={`rounded-tl-[3px] !leading-[8.8vw] font-bold rounded-br-[0] rounded-tr-[0] rounded-bl-[3px] border-l-[1px_#3b5160] w-full !leading-[40px] text-[0.938rem] ${selectedTime == 'tomorrow' ? `bg-[#3B5160] text-[#ffffff]` : `bg-[#ffffff] text-[#3B5160]`}`} onClick={() => setSelectedTime('tomorrow')}>
                                Tomorrow
                            </button>
                        </li>

                    </ul>
                </div>
                {cricketEvents.length > 0 && (
                    <div className='col-span-12 mb-[10px]'>
                        <div className='bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] '>
                            <h3 className='bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] text-[#ffffff] text-center  text-[0.875rem] leading-[30px] font-bold pl-[10px] m-0 cursor-pointer'>Cricket </h3>
                        </div>
                        <table className="w-full">
                            <tbody className='bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)]'>
                                {
                                    cricketEvents.map((event, index) => (
                                        <MobileTableRow key={index} row={event} inplay={true} />
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                )}
                {soccerEvents.length > 0 && (
                    <div className='col-span-12 mb-[10px]'>
                        <div className='bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] '>
                            <h3 className='bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] text-[#ffffff] text-center  text-[0.875rem] leading-[30px] font-bold pl-[10px] m-0 cursor-pointer'>Soccer </h3>
                        </div>
                        <table className="w-full">
                            <tbody className='bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)]'>
                                {
                                    soccerEvents.map((event, index) => (
                                        <MobileTableRow key={index} row={event} inplay={true} />
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                )}
                {tennisEvents.length > 0 && (
                    <div className='col-span-12 mb-[10px]'>
                        <div className='bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] '>
                            <h3 className='bg-[linear-gradient(-180deg,_#f6a21e_0%,_#cb7e05_100%)] text-[#ffffff] text-center  text-[0.875rem] leading-[30px] font-bold pl-[10px] m-0 cursor-pointer'>Tennis </h3>
                        </div>
                        <table className="w-full">
                            <tbody className='bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)]'>
                                {
                                    tennisEvents.map((event, index) => (
                                        <MobileTableRow key={index} row={event} inplay={true} />
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                )}

                <div className='col-span-12 overflow-x-auto overflow-y-hidden [scrollbar-width:none]'>
                    <ul className="flex flex-nowrap whitespace-nowrap overflow-x-auto overflow-y-hidden  text-sm font-medium text-center text-gray-500  [scrollbar-width:none] bg-[linear-gradient(-180deg,#f6a21e_0,_#cb7e05_100%)] " ref={scrollableDivRef}>
                        {casinoNav.map((item, index) => (
                            <li className="flex px-[4px] py-[2px]">
                                <button id={`item-${index}`} className={`p-[7px] min-w-[60px] text-[0.75rem] font-bold whitespace-nowrap uppercase [transition:color_.15s_ease-in-out,_background-color_.15s_ease-in-out,_border-color_.15s_ease-in-out] ${activeCasino == item.category ? `text-white` : `text-black border-r-[1px] border-black rounded-[40px]`}  `} onClick={(e) => {
                                    scrollToItem(index)
                                    setActiveCasino(item.category)
                                }}>{item.title}</button>
                            </li>
                        ))}
                    </ul>

                    <div className="grid grid-cols-12 gap-x-4 bg-[url('/public/other/casinobg.webp')] bg-contain bg-fixed p-[0.938rem] border-b-[1px] border-[#7e97a7]">
                        {
                            casino.map((casino) => (
                                <div className='col-span-4 relative  rounded-[5px] mb-[15px] cursor-pointer [box-shadow:0px_2px_4px]'>
                                    <div className='relative h-[90px] bg-[#000]  rounded-tl-[3px] rounded-tr-[3px]'>
                                        <div className='clip w-[100%] !h-[100px]'></div>
                                        <div className='clip2 !w-[100%] !left-[0vh]'>
                                            <img title="" alt="" className="w-full h-full" src={casino.path}></img>
                                        </div>
                                    </div>
                                    <div className='casinoFont w-full text-center text-[9px] h-[20px] items-center grid rounded-bl-[3px] rounded-br-[3px] font-bold uppercase bg-[linear-gradient(-180deg,_#f6a21e_0,_#cb7e05_100%)]'>
                                        {casino.title}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

            </div >
        </>
    );
}

export default Inplay;
