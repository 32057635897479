import '../../App.css'
import { LuClock9 } from "react-icons/lu";
import { Link } from 'react-router-dom';
import { signal } from "@preact/signals-react";
export const eventId = signal("");
function PoliticsOG() {
    return (
        <>
            {/* Desktop */}


            <table className="hidden lg:block w-full">
                <thead className="#dddcd6 bg-[#dddcd6] ">
                    <tr>
                        <th scope="col" className="text-[0.688rem] "></th>
                        <th scope="col" className="text-[0.688rem] "></th>
                        <th scope="col" className="text-[0.688rem] ">1</th>
                        <th scope="col" className="text-[0.688rem] ">X</th>
                        <th scope="col" className="text-[0.688rem] ">2</th>
                        <th scope="col" className="text-[0.688rem] "></th>
                    </tr>
                </thead>
                <tbody className='bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)]'>
                    <tr className="text-[0.75rem] border-[1px] border-[solid] border-[#c8ced3]">
                        <td className='!pl-[10px] !pr-[10px] !py-[3px]'>
                            <Link to={'/fullMarket'} className='flex flex-wrap gap-x-2 items-center'>
                                <span className='flex items-center gap-x-1 text-[#2789ce] font-bold' >
                                    Pohang Steelers v Jeonbuk Motors
                                </span>
                                <span className='flex  text-[#008000] font-bold'>
                                    <LuClock9 className='w-[.9rem] h-[.9rem] stroke-[3px]' />
                                </span>
                                <p className='text-[#777777] font-normal'> 23-04-2024 19:30 </p>
                            </Link>
                        </td>
                        <td className='!pl-[10px] !pr-[10px] !py-[3px]'>
                            <div className='flex items-center gap-1'>
                                <span className='text-white bg-[linear-gradient(180deg,_#0a92a5_15%,_#076875_100%)] px-[5px] py-[0] text-center italic font-bold rounded-[4px]'>F</span>
                                {/* <span className='text-white bg-[linear-gradient(180deg,_#0a92a5_15%,_#076875_100%)] px-[5px] py-[0] text-center italic font-bold rounded-[4px]'></span> */}
                                <span className='text-white bg-[linear-gradient(-180deg,#f6a21e,#cb7e05)] pl-[2px] pr-[4px] py-[0] text-center italic font-bold rounded-[4px]'>BM</span>
                            </div>
                        </td>
                        <td className="!pl-[10px] !pr-[10px] !py-[3px] ">
                            <div className='flex items-center '>
                                <span className='inline-flex text-[0.688rem] bg-[#72bbef] text-[#1e1e1e] text-center px-[10px] py-[2px] font-bold min-w-[50px] '>
                                    3.55
                                </span>
                                <span className='inline-flex text-[0.688rem] bg-[#faa9ba] text-[#1e1e1e] px-[10px] py-[2px] font-bold min-w-[50px]'>
                                    3.6
                                </span>
                            </div>
                        </td>
                        <td className="!pl-[10px] !pr-[10px] !py-[3px]">
                            <div className='flex items-center '>
                                <span className='inline-flex text-[0.688rem] bg-[#72bbef] text-[#1e1e1e] px-[10px] py-[2px] font-bold min-w-[50px] '>
                                    5.2
                                </span>
                                <span className='inline-flex text-[0.688rem] bg-[#faa9ba] text-[#1e1e1e] px-[10px] py-[2px] font-bold min-w-[50px]'>
                                    5.3
                                </span>
                            </div>
                        </td>
                        <td className="!pl-[10px] !pr-[10px] !py-[3px] text-center">
                            <div className='flex items-center '>
                                <span className='inline-flex text-[0.688rem] bg-[#72bbef] text-[#1e1e1e] px-[10px] py-[2px] font-bold min-w-[50px] '>
                                    4.8
                                </span>
                                <span className='inline-flex text-[0.688rem] bg-[#faa9ba] text-[#1e1e1e] px-[10px] py-[2px] font-bold min-w-[50px]'>
                                    5
                                </span>
                            </div>
                        </td>
                        <td className="!py-[3px] text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" color='#7e97a7' className='h-[20px]'>
                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path></svg>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* Mobile */}
            <table className="lg:hidden w-full">
                <tbody className='bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)]'>
                    <tr className="text-[1.08rem] border-[1px] border-[#c8ced3]">
                        <td className='!pl-[10px] !pr-[10px] !py-[3px] max-w-[50vw]'>
                            <Link to={'/fullMarket'} className='flex flex-wrap gap-x-2 items-center'>
                                <span className='flex items-center gap-x-1 text-[#2789ce] font-bold' >
                                    LOK SABHA ELECTION - 2024
                                </span>
                                <span className='flex  text-[#008000] font-bold'>
                                    <LuClock9 className='w-[.9rem] h-[.9rem] stroke-[3px]' />
                                </span>
                                <p className='text-[0.75rem] text-[#777777] font-normal'> 23-04-2024 19:30 </p>
                            </Link>
                        </td>
                        <td className='!pl-[10px] !pr-[10px] !py-[3px]'>
                            <div className='flex items-center gap-1'>
                                <span className='text-[1.08rem] text-white bg-[linear-gradient(-180deg,#f6a21e,#cb7e05)] pl-[2px] pr-[4px] py-[0] text-center italic font-bold rounded-[4px] leading-[20px]'></span>
                                <span className='text-[1.08rem] text-white bg-[linear-gradient(180deg,_#0a92a5_15%,_#076875_100%)] px-[5px] py-[0] text-center italic font-bold rounded-[4px] leading-[20px]'>F</span>
                                <span className='text-[1.08rem] text-white bg-[linear-gradient(180deg,_#f26d1c_15%,_#d14100_100%)] px-[5px] py-[0] text-center italic font-bold rounded-[4px] leading-[20px]'></span>


                            </div>
                        </td>
                        <td className="!py-[3px] text-center">
                            <a className='w-[6.66667vw] h-[6.66667vw] p-0 bg-no-repeat text-[11px] leading-[13px] block bg-contain text-center text-[#7e97a7] '>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" color='#7e97a7' className='text-[11px] leading-[13px] text-center text-[#7e97a7]'>
                                    <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path></svg>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default PoliticsOG;
